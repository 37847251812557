import React, { useEffect } from 'react';
import logo from '../assets/images/logo-2.png';
import StickyMenu from '../lib/StickyMenu.js';
// import Navigation from './Navigation.jsx';

function HeaderHomeTwo({ action }) {
    useEffect(() => {
        StickyMenu();
    }, []);

    return (
        <>
            <header className="appie-header-area appie-header-2-area appie-sticky md:px-10">
                <div className="container">
                    <div className="header-nav-box">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="appie-logo-box mr-4">
                                    <a href="/">
                                        <img src={logo} alt="Logo" className="logo logo-1" />
                                    </a>
                                </div>
                                <div className="appie-header-main-menu">
{/*                                     <Navigation /> */}
                                </div>
                            </div>
                            <div className="appie-btn-box flex justify-end mr-3">
                                <a className="main-btn ml-30" href="https://crm.qrps.ca/login">
                                    Get Started
                                </a>
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HeaderHomeTwo;
